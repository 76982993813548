import store from '@/store/store';
import brand from 'Services/whiteLabels/brands';

const patientLabel = () => `general.words.${store.getters['enrollments/patientsLabel']}`;

export default {
  components: {
    audio: {
      unSupportBrowser: 'Deze audio wordt niet ondersteund door je browser.',
    },
    carePlanCreateSideBar: {
      hasPrefilledDataErrorTitle: 'Je kunt niet verder, omdat er cliëntgegevens missen.',
      hasPrefilledDataErrorText: 'Ga terug naar het HIS of EPD, vul de missende gegevens van deze persoon daar in en maak dan een nieuwe uitnodiging.',
    },
    formGroupPassword: {
      passwordMust: 'Wachtwoord moet',
    },
    landingLayout: {
      help: 'Hulp nodig?',
      Therapieland: {
        mentalCare: 'Ontdek e-Mental health behandelingen voor jouw zorg',
      },
      Gezondeboel: {
        mentalCare: 'Gezondeboel geeft je het vermogen om veerkrachtig te leven',
      },
    },
    navigationComponent: {
      confirmLogout: 'Uitloggen bevestigen',
      logoutConfirmation: 'Weet je zeker dat je wilt uitloggen?',
    },
    pagination: {
      numericPagination: {
        showResults: 'Toon {pageSize} resultaten',
        noResultsFound: 'Geen resultaten gevonden.',
      },
      loadMore: 'Laad meer',
      noData: 'Er zijn geen gegevens.',
      scroll: 'Scroll naar beneden om meer te zien.',
    },
    searchComponent: {
      limitReached: 'Maximaal {count} deelnemers zijn toegestaan',
      participantAlreadyAdded: 'Deze deelnemer is al toegevoegd',
    },
    shareComponent: {
      copyLink: 'Kopieer link',
      inviteProfessional: 'Professional uitnodigen',
      letOthersHelpYou: 'Laat anderen je helpen',
      linkCopied: 'Link gekopieerd',
      invited: 'Uitgenodigd',
      othersIntro: 'Nodig vrienden, familie of bekenden uit. Dit zal hen helpen te begrijpen waar jij aan werkt, waardoor zij jou beter kunnen steunen.',
      othersNote: 'Anderen hebben geen toegang tot uw persoonlijke gegevens of ingevulde antwoorden binnen het programma.',
      preview: 'Voorbeeld',
      professionalIntro: 'Nodig een professional uit die jou begeleidt of helpt met jouw zelfontwikkeling. Dit kan een coach, behandelaar, begeleider of een andere professional zijn.',
      professionalNote: '<b>Deze persoon krijgt alleen toegang tot het programma of de vragenlijst waarvoor jij toestemming geeft.</b> Jullie kunnen ook berichten uitwisselen zodat jullie samen kunnen werken aan jouw doelen.',
      share: 'Deel',
      shareWith: 'Deel met meer',
    },
    treatmentAssignmentGoal: {
      addNewGoal: 'Voeg een nieuw doel toe',
      confirmationModal: 'Als je je doel wil aanpassen, raden we je aan om een nieuw doel toe te voegen, zodat je je vorige score op dit doel niet kwijt raakt. Wil je je doel toch aanpassen?',
      goalPlaceholder: 'Vul hier je doel in...',
      intro: 'Hieronder kan je je doel(en) voor dit programma opstellen. Met het schuifje kan je aangeven hoe ver je bent met het bereiken van dit doel. We zullen hier later weer op terug komen.',
      maxLabel: 'Bereikt',
      minLabel: 'Niet bereikt',
      noGoalTextSet: 'Nog geen doel gesteld',
      seePreviousGoals: 'Toon eerdere scores',
      submitNewGoal: 'Sla nieuw doel op',
      title: 'Doel',
    },
    treatmentStepMenu: {
      title: 'Programma overzicht',
      motivationalQuote: 'Je bent goed op weg!',
    },
    treatmentVideoElement: {
      summary: 'Samenvatting',
      text: 'Tekst',
    },
    widgetAnalytics: {
      active: ({ linked }) => `${linked(patientLabel())} die de laatste 2 weken zijn ingelogd.`,
      errorMessage: 'Er is een fout opgetreden bij het ophalen van de gegevens. Vernieuw de pagina om het opnieuw te proberen.',
      inactive: ({ linked }) => `${linked(patientLabel())} die de laatste 2 weken niet zijn ingelogd.`,
      invited: ({ linked }) => `${linked(patientLabel())} die hun uitnodiging nog niet geaccepteerd hebben.`,
      loggedInRecently: 'Recent ingelogd',
      notLoggedInRecently: 'Niet recent ingelogd',
      openInvites: 'Openstaande uitnodigingen',
    },
    widgetVideoCall: {
      addParticipants: 'Deelnemer toevoegen',
      appointmentConfirmation: 'Afspraakbevestiging',
      errorTimeStart: 'De begintijd van de vergadering kan niet in het verleden liggen',
      goToWSDPortal: 'Ga naar al mijn beeldbel afspraken',
      inviteFailed: 'De uitnodiging kon niet worden aangemaakt',
      inviteSuccess: 'De deelnemers worden uitgenodigd',
      meetTitle: '{name} | {name} + {total} deelnemer | {name} + {total} deelnemers',
      modalError: 'De verbinding met het portaal is mislukt. Probeer het opnieuw of navigeer naar de beeldbel afspraken.',
      newAppointment: 'Nieuwe afspraak',
      noAppointments: 'Geen beeldbel afspraken de komende 12 uur',
      openPortal: 'Open het portaal',
      searchNameEmail: 'Zoeken op voornaam of e-mailadres',
      videoCall: 'Beeldbellen',
      widgetError: 'Kon geen gegevens ophalen. Klik op de onderstaande knop om uw afspraken te bekijken',
    },
    widgets: {
      monitorAddRecordError: 'Er is iets mis gegaan met het opslaan, probeer het nog eens.',
      monitorAddRecordSuccess: 'Opslaan gelukt! Bekijk je resultaat op de Monitor-pagina.',
      monitorAdvice: 'Krijg meer inzicht in je dagen door de monitors in te vullen!',
      monitorCreate: 'Maak een monitor',
      monitorEmptyState: 'Je hebt nog geen monitor. Start met het bijhouden van jouw emoties of je gedrag om meer inzicht in jouw dagen te krijgen!',
      monitorSeeAll: 'Bekijk je monitors',
      monitorTitle: 'Monitor',
      newsDefaultContent: `${brand.name} wil graag toegankelijk zijn voor iedereen! Hoe? Met een online e-Health platform voor programma's, vragenlijsten, virtual reality, apps en meer!`,
      newsDefaultTitle: `${brand.name} overzicht`,
      noTopTreatmentsFound: "Geen top-programma's gevonden",
      noTreatmentsFound: 'Geen programma’s gevonden.',
      recommendedTreatments: 'Aanbevolen programma\'s',
      recommendedTreatmentsSubtitle: 'Ontdek programma’s die jouw organisatie aanbeveelt',
    },
  },
  general: {
    actions: {
      accept: 'Accepteer',
      add: 'Toevoegen',
      addADate: 'Voeg een datum toe',
      addAnotherDate: 'Voeg nog een datum toe',
      added: 'Toegevoegd',
      addMore: 'Meer toevoegen',
      addMoreToCarePlan: 'Meer aan werkmap toevoegen',
      addNewMonitor: 'Nieuwe monitor toevoegen',
      addNewPatient: ({ linked }) => `${linked(patientLabel())} toevoegen`,
      addToCarePlan: 'Toevoegen aan werkmap',
      admit: 'Binnenlaten',
      all: 'Alles',
      archive: 'Archiveren',
      assigned: 'Toegevoegd',
      availableOn: 'Beschikbaar op',
      back: 'Terug',
      cancel: 'Annuleren',
      change: 'Aanpassen',
      changePassword: 'Wijzig wachtwoord',
      clearAll: 'Alles verwijderen',
      close: 'Sluiten',
      closeAll: 'Alles inklappen',
      completed: 'Voltooid',
      completedOn: 'Voltooid op',
      confirm: 'Bevestigen',
      continue: 'Verdergaan',
      continueTreatment: 'Doorgaan met programma',
      copied: 'Gekopieerd',
      copy: 'Kopieren',
      create: 'Aanmaken',
      deactivate: 'Deactiveer',
      decline: 'Afwijzen',
      delete: 'Verwijderen',
      deleteText: 'Wil je "{element}" verwijderen?',
      denyEntry: 'Weigeren',
      done: 'Klaar',
      edit: 'Aanpassen',
      expand: 'Vergroten',
      expandAll: 'Alles uitklappen',
      export: 'Downloaden',
      filledIn: 'Ingevuld',
      getStarted: 'Start',
      goToQuestionnaire: 'Ga naar vragenlijst',
      hide: 'Verbergen',
      hideProgress: 'Verberg voortgang',
      inProgress: 'In behandeling',
      invite: 'Nodig uit',
      join: 'Aanmelden',
      lastReminderOn: 'Laatste herinnering op',
      loading: 'Laden',
      loggingIn: 'Bezig met inloggen',
      login: 'Inloggen',
      logout: 'Uitloggen',
      message: 'Stuur bericht',
      next: 'Volgende',
      open: 'Open',
      openInvites: 'Niet geaccepteerd',
      openQuestionnaire: 'Open vragenlijst',
      pay: 'Betalen',
      pending: 'In afwachting',
      professionalStatus: 'Geaccepteerd | Uitgenodigd | Afgewezen',
      progress: 'Voortgang',
      reconnect: 'Reconnect',
      reduce: 'Verkleinen',
      register: 'Registreren',
      remove: 'Verwijderen',
      resendCode: 'Code opnieuw versturen',
      review: 'Bekijken',
      save: 'Opslaan',
      saveChanges: 'Wijzigingen opslaan',
      scheduled: 'Gepland',
      scheduledOn: 'Gepland op',
      scheduleRecurrence: 'Plan een herhaling',
      search: 'Zoeken',
      selectAll: 'Selecteer alles',
      send: 'Versturen',
      sendAgain: 'Nogmaals versturen',
      sendEmail: 'Stuur e-mail',
      sendInvitation: 'Verzend uitnodiging',
      sendMessage: 'Voeg een bericht toe',
      sendReminder: 'Stuur herinnering',
      sendTo: 'Verstuur naar',
      sent: 'Verstuurd',
      share: 'Delen',
      shareWith: 'Deel met',
      showOnly: 'Toon alleen',
      showProgress: 'Toon voortgang',
      start: 'Start',
      started: 'Gestart',
      startedOn: 'Gestart op',
      startTreatment: 'Start programma',
      startWith: 'Start met',
      to: 'Naar',
      tryAgain: 'Try again',
      validOn: 'Geldig op',
    },
    days: {
      mon: 'Ma',
      tue: 'Di',
      wed: 'Wo',
      thu: 'Do',
      fri: 'Vr',
      sat: 'Za',
      sun: 'Zo',
    },
    months: {
      january: 'januari',
      february: 'februari',
      march: 'maart',
      april: 'april',
      may: 'mei',
      june: 'juni',
      july: 'juli',
      august: 'augustus',
      september: 'september',
      october: 'oktober',
      november: 'november',
      december: 'december',
    },
    errors: {
      accessDenied: 'Toegang geweigerd',
      component: 'Oeps... Er is iets mis gegaan. We gaan op zoek naar wat er is gebeurd.',
      emailSend: 'Het verzenden van de e-mail is mislukt. Probeer het later nog eens.',
      expiredToken: 'Sorry, deze link is niet meer geldig!',
      internalError: 'Interne server fout',
      issueReference: 'Uitgifte referentie: {code}',
      needHelp: "Hulp nodig? Bel onze helpdesk op: <a href='tel:+31207712848'>020-7712848</a>.",
      notFound: 'Pagina niet gevonden',
      professionalJoinOrganisation: 'U kunt zich niet als professional bij {organisation_name} aansluiten',
      useEPDForInvites: ({ linked }) => `Je moet het EPD gebruiken om nieuwe ${linked(patientLabel())} uit te nodigen.`,
    },
    filters: {
      active: 'Recent ingelogd',
      adults: 'Volwassenen',
      categoryFilter: 'Categorie filter',
      children: 'Kinderen',
      dailyActivities: 'Daginvulling',
      finances: 'Financiën',
      health: 'Gezondheid',
      inactive: 'Niet recent ingelogd',
      justQuestionnaires: 'Alleen vragenlijsten',
      living: 'Wonen',
      personal: 'Persoonlijk',
      rejected: 'Geweigerd',
      socialContacts: 'Sociale contacten',
      targetGroup: 'Geschikt voor:',
      youth: 'Jongeren',
    },
    formFields: {
      dateOfBirth: 'Geboortedatum',
      education: 'Onderwijs',
      email: 'E-mail',
      employabilityVitality: 'Arbeid & Vitaliteit',
      firstName: 'Voornaam',
      generalPractitioners: 'Huisartsenzorg',
      ggz: 'GGZ',
      hospitalCare: 'Ziekenhuiszorg',
      infix: 'Voorvoegsel',
      kvkNumber: 'KVK-Nummer (of btw-nummer)',
      lastName: 'Achternaam',
      message: 'Bericht (optioneel)',
      name: 'Naam',
      organisationName: 'Organisatie naam',
      organisationType: 'Organisatie type',
      other: 'Overig',
      others: 'Anderen',
      password: 'Wachtwoord',
      passwordStrengthTooltipTitle: {
        weak: 'Zwak wachtwoord',
        moderate: 'Matig wachtwoord',
        strong: 'Sterk wachtwoord',
        veryStrong: 'Zeer sterk wachtwoord',
      },
      passwordStrengthTooltipContent: 'Kies een wachtwoord dat anderen niet makkelijk kunnen raden. Gebruik ongebruikelijke woorden of woordgrapjes, onverwachte HOOFDletters, creatieve spelling, cijfers en $ymbolen. Het houdt je persoonlijke informatie veilig.',
      phone: 'Telefoon',
      reference: `Hoe of waar heb je voor het eerst van ${brand.name} gehoord? (optioneel)`,
      role: 'Rol',
      socialDomain: 'Social Domein',
      username: 'Gebruikersnaam',
      viaAdvertisingCampaign: 'Via een (advertentie) campagne',
      viaArticleOrNews: 'Via een artikel of nieuwsbericht',
      viaEvent: 'Via een event (online of offline)',
      viaSearchEngine: 'Via een zoekmachine',
      wordOfMouth: 'Mond-tot-mondreclame',
    },
    formGeneralError: {
      carePlanAlreadyExists: 'Deze patiënt is al uitgenodigd.',
      careTakerEmailAsCareGiver: 'Je kan geen uitnodiging naar jezelf versturen.',
      default: 'Opslaan mislukt. Probeer het nog eens.',
      emailAlreadyExist: 'E-mailadres is al in gebruik.',
      enrollmentOrganisationRestrictInvitingPatients: 'Je moet je EPD gebruiken om cliënten uit te nodigen.',
      incorrectEmailPassword: 'Oeps, deze combinatie kennen we niet. Kan gebeuren natuurlijk, probeer het nog eens!',
      invalidEmail: 'Dit e-mailadres is niet geldig.',
      invalidOldPassword: 'Oud wachtwoord is niet geldig',
      invalidPassword: 'Dit wachtwoord is niet geldig',
      nonExistentTreatment: 'Het lijkt erop dat de organisatie dit programma niet aanbiedt, annuleer om terug te gaan naar het dashboard',
      notPossibleToSendThisInvitation: 'Het is niet mogelijk om deze uitnodiging te versturen. Neem contact op met de helpdesk, zij kunnen je verder helpen.',
      organisationAlreadyExists: `Deze organisatie bestaat al in ons systeem. Neem voor een nieuw account contact met ons op via <a href='mailto:${brand.emailInfo}'>${brand.emailInfo}</a> of <a href='tel:+31207712848'>020 771 2848</a> en dan helpen wij je verder.`,
      professionalAlreadyExists: 'Oeps.. deze behandelaar is al toegevoegd aan deze gebruiker.',
      registeredUserAlreadyExists: 'Deze gebruiker bestaat al.',
      treatmentAssignmentAlreadyExists: 'Oeps.. deze behandeling is al toegevoegd aan deze gebruiker.',
      tryLater: 'Er is iets mis gegaan, probeer het later nog eens.',
      unacceptedPrivacyAgreement: 'De gebruikersvoorwaarden zijn nog niet geaccepteerd.',
    },
    formGroupError: {
      between: 'Waarde moet tussen {min} en {max} zitten.',
      blank: 'Veld is leeg.',
      capital: '1 hoofdletter',
      containsFigure: '1 speciaal teken',
      default: 'Veld is niet geldig.',
      email: 'Moet een correct e-mail adres zijn.',
      emailIsUnique: 'Je hebt dit e-mailadres al gebruikt.',
      identical: 'Wachtwoorden moeten identiek zijn.',
      invalid: 'Veld is ongeldig.',
      emptyDates: 'Alle data moeten zijn ingevuld.',
      maxLength: 'Bevat maximaal {length} tekens',
      minLength: 'Minstens {length} tekens',
      notIdenticalEmail: 'Dit is je huidige e-mailadres.',
      notSameTime: 'Kan niet gelijk zijn',
      required: 'Verplicht veld.',
      tel: 'Moet een correct telefoonnummer zijn 123456789.',
    },
    navigation: {
      backToCarePlan: 'Terug naar werkmap',
      backToChat: 'Terug naar berichten',
      backToDashboard: 'Terug naar dashboard',
      backToLogin: 'Terug naar login',
      backToOverview: 'Terug naar overzicht',
      backToWebsite: 'Terug naar website',
      goBack: 'Ga terug',
      goToCarePlan: 'Ga naar werkmappen',
      goToDashboard: 'Ga naar dashboard',
      goToEPD: 'Ga naar het EPD',
      goToLogin: 'Ga naar inloggen',
      goToLoginScreen: 'Ga naar het loginscherm',
      goToMonitors: 'Ga naar monitors',
      goToQuestionnaire: 'Ga naar vragenlijst',
      goToTreatments: 'Ga naar programma overzicht',
    },
    notifications: {
      acceptCarePlan: ({ linked }) => `Een ${linked(patientLabel())} heeft zijn/haar uitnodiging geaccepteerd.`,
      acceptMultiProfessionalCarePlan: '{care_giver_first_name} heeft de uitnodiging geaccepteerd voor de werkmap van {care_taker_first_name}.',
      acceptMonitorRequest: '{care_taker_name} heeft uw verzoek voor toegang tot monitors geaccepteerd.',
      acceptTreatment: 'Je behandelaar heeft de uitnodiging om je behandeling(en) te delen geaccepteerd.',
      addSelfStartTreatment: ({ linked }) => `Een ${linked(patientLabel())} is een zelfhulpbehandeling gestart.`,
      addSlugOrganisationCarePlan: 'Je bent lid geworden {organisation_name}.',
      archiveCareTaker: '{care_giver_name} heeft je werkmap gearchiveerd.',
      deactivateCareTaker: ({ linked }) => `Een ${linked(patientLabel())} heeft zijn/haar account gedeactiveerd`,
      informantCompletedQuestionnaire: 'Een informant heeft de {questionnaire_name} ingevuld over {care_taker_name}.',
      noNewNotification: 'Geen nieuwe notificaties',
      notification: 'Je hebt een notificatie',
      patientCompletedQuestionnaire: '{care_taker_name} heeft de {questionnaire_name} ingevuld.',
      patientNotLogged: ({ linked }) => `Een ${linked(patientLabel())} heeft al twee weken niet meer ingelogd.`,
      professionalAddedToCarePlan: '{inviter_name} heeft {invitee_name} toegang gegeven tot jouw werkmap.',
      refuseCarePlan: ({ linked }) => `Een ${linked(patientLabel())} heeft zijn/haar uitnodiging geweigerd.`,
      refuseMultiProfessionalCarePlan: '{care_giver_first_name} heeft de uitnodiging afgewezen voor de werkmap van {care_taker_first_name}.',
      refuseMonitorRequest: '{care_taker_name} heeft uw verzoek om toegang tot monitors geweigerd.',
      refuseTreatment: 'Uw behandelaar heeft de uitnodiging om uw behandeling(en) te delen geweigerd.',
      reminderNotSent: 'Herinnering niet verstuurd',
      reminderSent: 'Herinnering verstuurd',
      shareTreatment: ({ linked }) => `Een ${linked(patientLabel())} heeft een behandeling met je gedeeld.`,
      updateCarePlan: 'Je behandelaar heeft iets veranderd in je werkmap.',
    },
    payment: {
      paymentCanceled: 'Betaling is geannuleerd',
      paymentExpired: 'Betaling is verlopen',
      paymentFailed: 'Betaling is mislukt',
      paymentSucceeded: 'Betaling is gelukt',
    },
    searchPlaceholders: {
      searchPatients: ({ linked }) => `Hier kan je naar al je ${linked(patientLabel())} zoeken. Vanuit privacy overwegingen adviseren we je op de volledige naam te zoeken.`,
      searchFor: 'Zoeken ...',
    },
    titles: {
      403: 'Toestemming geweigerd',
      404: 'Niet gevonden',
      500: 'Interne server fout',
      activateAccount: 'Activeer je account',
      addMoreToCarePlan: 'Meer aan werkmap toevoegen',
      administration: 'Administratie',
      carePlan: 'Werkmap',
      carePlanAcceptInvitations: 'Accepteer werkmap',
      carePlanCreate: 'Nodig uit',
      carePlanNoPermission: 'Nodig uit - geen toestemming',
      changeEmailSuccess: 'E-mail succesvol gewijzigd',
      changePassword: 'Wijzig wachtwoord',
      chat: 'Berichten',
      coaching: 'Coaching',
      createdAccount: 'Account aangemaakt',
      dashboard: 'Dashboard',
      dataStorageAgreement: 'Data opslag overeenkomst',
      deactivatedAccount: 'Gedeactiveerd account',
      deleteAccount: 'Verwijderen account',
      deletedAccount: 'Verwijderd account',
      diary: 'Dagboek',
      error: 'Fout',
      expiredToken: 'Verlopen token',
      forgotPassword: 'Wachtwoord vergeten',
      groups: 'Groepen',
      home: `E-Health van ${brand.name}`,
      krachtplanPermission: 'Krachtplan Toestemming',
      login: 'Inloggen',
      loginWithToken: 'Inloggen met token',
      monitor: 'Monitors',
      monitorAcceptRequests: 'Accepteer monitor',
      patient: ({ linked }) => `${linked(patientLabel())}`,
      patientCarePlans: 'Patiënt werkmappen',
      patientShared: ({ linked }) => `Gedeelde ${linked(patientLabel())}`,
      patients: ({ linked }) => `${linked(patientLabel())}`,
      questionnaireAssignment: 'Vragenlijst',
      questionnaireInformant: 'Informantvragenlijst',
      questionnaireList: 'Vragenlijsten',
      register: 'Registreren',
      registerOrganisation: 'Aanvraag voor organisatie',
      securityCode: 'Beveiligingscode',
      settings: 'Instellingen',
      ssoValidation: 'Single sign-on',
      survey: 'Tevredenheidsmeting',
      treatmentAssignmentsShare: 'Programma’s delen',
      treatmentDetail: 'Programma',
      treatmentInformation: 'Informatie',
      treatmentList: "Programma's",
      treatmentPayment: 'Betaling voor programma',
      treatmentStep: 'Stap',
      twoFactorAuthentication: 'Twee Factor Authenticatie',
      userNameInformation: 'Gebruikernaam informatie',
    },
    userRoles: {
      professional: 'Professional',
    },
    words: {
      account: 'Account',
      active: 'Actief',
      activities: ({ linked }) => `${linked(patientLabel())} activiteiten`,
      bad: 'Slecht',
      carePlan: 'Werkmap',
      carePlanContent: 'Inhoud werkmap',
      citizen: 'burger | burgers',
      client: 'cliënt | cliënten',
      content: 'Inhoud',
      count: 'Aantal',
      current: 'Huidige',
      customer: 'klant | klanten',
      date: 'Datum',
      employee: 'medewerker | medewerkers',
      enduser: 'eindgebruiker | eindgebruikers',
      error: 'fout',
      goal: 'Doel',
      good: 'Goed',
      great: 'Top',
      help: 'Help',
      inactive: 'Inactief',
      informant: 'Informant | Informanten',
      invited: 'Uitgenodigd',
      invitee: 'Uitgenodigde | Uitgenodigden',
      inviter: 'Uitnodiger',
      language: 'Taal',
      mins: '{time} mins',
      negative: 'Matig',
      new: 'Nieuw',
      newPassword: 'Nieuw wachtwoord',
      no: 'Nee',
      noEmail: 'Geen email',
      noPermission: 'Geen toestemming',
      notifications: 'Notificaties',
      off: 'Uit',
      ok: 'Ok',
      oldPassword: 'Oud wachtwoord',
      on: 'Aan',
      overview: 'Overzicht',
      participants: 'Deelnemer | Deelnemers',
      passwordConfirmation: 'Bevestig wachtwoord',
      patient: 'patiënt | patiënten',
      professional: 'Professional',
      professionalEg: 'bijv. therapeut, huisarts of coach',
      pupil: 'leerling | leerlingen',
      questionnaire: 'Vragenlijst | Vragenlijsten',
      recipient: 'Ontvanger',
      records: 'Scores',
      resident: 'buurtbewoner | buurtbewoners',
      results: 'Resultaat | Resultaten',
      showLess: 'Toon minder',
      showMore: 'Toon meer',
      student: 'student | studenten',
      subject: 'Onderwerp',
      time: 'Tijd',
      today: 'Vandaag',
      tomorrow: 'Morgen',
      total: 'Totaal',
      treatment: "Programma | Programma's",
      unknown: 'Niet bekend',
      videoCall: 'Videogesprek | Videogesprekken',
      week: 'Week',
      yes: 'Ja',
    },
  },
  views: {
    acceptMonitorPermissionRequests: {
      contentTitle: 'Monitor(s)',
      firstContent: '{requester}  wil graag meekijken met jouw monitor(s). <br><br> Heb je nog geen monitor aangemaakt? Maak deze dan eerst aan.',
      secondContent: 'Met een monitor kun je gewoonten en activiteiten vastleggen en met jouw zorgverlener evalueren. Je kunt bijvoorbeeld inzicht krijgen in hoevaak je rookt of drinkt, om vervolgens samen te kijken waar dit gedrag vandaan komt en welke oplossingen er eventueel zijn voor jouw situatie. <br><br>Geef toestemming of weiger het verzoek om mee te kijken met jouw monitor(s).',
      title: 'Je bent uitgenodigd om Monitors te gebruiken',
    },
    activateAccount: {
      accountCreatedSucceed: 'Je account is geactiveerd!',
      authorization: `Ik geef Therapielandtoestemming om mijn persoonlijke gegevens te beheren en ik ga akkoord met het <a href='${brand.websitePrivacy}' target='_blank'>privacy statement</a> van Therapieland`,
      deleteAccount: `Ik wil geen gebruik maken van ${brand.name}`,
      haveAccount: 'Heb je al een account?',
      loginPromptDescription: 'Je probeert een account te activeren, maar je bent nog ingelogd met een ander account. Log eerst uit voordat je een nieuw account activeert.',
      loginPromptTitle: 'Je bent nog ingelogd',
      placeholderPassword: 'Stel een wachtwoord in',
      placeholderUsername: 'Stel een gebruikersnaam in',
      privacy: 'privacy verklaring',
      research: `Ik geef toestemming aan ${brand.name} om mijn geanonimiseerde gegevens te gebruiken voor het doen van onderzoek. Lees meer <a href='${brand.websiteResearch}' target='_blank'>over onderzoekstoestemming</a>.`,
      resendEmailAfter: 'Verstuurd. Je kunt over 5 seconden een nieuwe e-mail versturen.',
      termsAndConditions: `Ik ga akkoord met de <a href='${brand.websiteTerms}' target='_blank'>Gebruikersvoorwaarden</a> en de <a href='${brand.websitePrivacy}' target='_blank'>Privacystatement</a>.`,
      title: 'Activeer je account',
      uspParagraph: `${brand.name} heeft de missie om mensen te bekrachtigen een gezond leven te leiden door psychologische expertise universeel beschikbaar en inzetbaar te maken, op iedere plek, op ieder moment.`,
      uspTitle: 'E-Health die je voelt, die je raakt, die je oppakt!',
    },
    carePlanAcceptInvitations: {
      carePlanInvitation: 'Werkmap uitnodiging',
      carePlanInvitationInfo: "Je hebt deze uitnodiging ontvangen van een collega. Als je een werkmap uitnodiging accepteert, krijg je toegang tot de bestaande werkmap van deze cliënt. Hij of zij zal zichtbaar zijn in je cliënten overzicht. Je kunt alle programma's en vragenlijsten inzien, zoals dat het geval zou zijn als je de cliënt zelf had uitgenodigd.",
      carePlanInvitationFrom: 'Werkmap uitnodiging van {care_giver}',
      hasInviteYou: `{care_giver} van {organisation_name} heeft je uitgenodigd om ${brand.name} te gebruiken. Je kunt werken aan behandelingen en/of vragenlijsten van jouw professional. Communiceer met je professional via ${brand.name}, gebruik een extra tool om jezelf sterker te maken en deel je voortgang met je persoonlijke netwerk.`,
      invitationAccepted: 'De uitnodiging is geaccepteerd',
      invitationRefused: 'De uitnodiging is geweigerd',
      programInvitation: 'Programma uitnodiging',
      programInvitationFrom: "Programma uitnodiging van {care_taker} | Programma's uitnodiging van {care_taker}",
      programInvitationInfo: 'De cliënt heeft jou uitgenodigd voor zijn of haar programma. Je kunt zien wat er gedaan is, en je kan berichten uitwisselen met deze cliënt.',
      questionDeclineInvitation: 'Wil je de uitnodiging afslaan?',
      selectedBy: 'Geselecteerd door {care_giver}',
      selectedQuestionnaires: 'Geselecteerd vragenlijst | Geselecteerde vragenlijsten',
      selectedTreatments: 'Geselecteerd programma | Geselecteerde programma’s',
      selectedTreatmentsAndQuestionnaires: 'Je professional selecteerde behandelingen en/of vragenlijsten om mee aan de slag te gaan.',
      yourHaveBeenInvitedInvitation: `Je bent uitgenodigd om ${brand.name} te gebruiken.`,
      yourInvitation: 'Jouw uitnodiging | Jouw uitnodigingen',
    },
    carePlanAdd: {
      assignmentInCarePlan: 'Al beschikbaar in werkmap',
      modalInfoWithoutEmail: ({ linked }) => `Let op: Er is geen e-mailadres bekend van deze ${linked(patientLabel())}, dus het is niet mogelijk voor de ${linked(patientLabel())} om deze opdracht zelfstandig uit te voeren.`,
    },
    carePlanCreate: {
      addAnotherInformant: 'Voeg nieuwe informant toe',
      addCarePlan: 'Toevoegen aan werkmap',
      addCarePlanText: ({ linked }) => `Selecteer hier programma's en vragenlijsten voor in de werkmap van de ${linked(patientLabel())}.`,
      addGoalPlaceholder: 'Voeg een doel toe...',
      addGoalText: ({ linked }) => `Wil je een doel toevoegen aan de werkmap? Het kan de motivatie van de ${linked(patientLabel())} versterken.`,
      addInformant: 'Informant toevoegen',
      addProfessional: 'Voeg behandelaar toe',
      allQuestionnaires: 'Alle vragenlijsten',
      allTreatments: "Alle programma's",
      cannotSentWithoutInformant: 'Zonder informant kan de vragenlijst niet worden verzonden',
      changeProfessional: 'Wijzig behandelaar',
      filledByInformant: 'In te vullen door een informant',
      filledByProfessional: 'In te vullen door behandelaar',
      filledByYou: 'In te vullen door jou',
      finalize: 'Afronden',
      informantAtLeast1: 'Voeg minstens 1 informant toe om de uitnodiging te versturen.',
      informantLimitReached: 'Je kunt niet meer dan {limit} informanten per uitnodiging toevoegen.',
      informantQuestionnaire: 'Informantvragenlijst',
      informantQuestionnaireEg: 'bijvoorbeeld: leerkracht, ouder, etc.',
      informantQuestionnaireModalTitle: 'Wijs informanten toe aan de vragenlijst',
      informantQuestionnaireText: 'Deze vragenlijst moet door een informant ingevuld worden. Vul hier de gegevens van die persoon in.',
      invitationSuccess: 'De uitnodiging is verstuurd',
      invitationWithoutEmailSuccess: ({ linked }) => `De ${linked(patientLabel())} is toegevoegd`,
      invite: ({ linked }) => `Nodig een nieuwe ${linked(patientLabel())} uit`,
      inviteText: ({ linked }) => `Vul eerst de gegevens in van jouw ${linked(patientLabel())}.`,
      inviteWithoutEmail: ({ linked }) => `Nodig een nieuwe ${linked(patientLabel())} uit zonder e-mail adres`,
      inviteWithoutEmailButton: ({ linked }) => `${linked(patientLabel())}  toevoegen`,
      noAssignments: 'Er staat nog niets in de werkmap.',
      noInformantsAdded: 'Geen informanten toegevoegd.',
      noInformantsAddedYet: 'Er zijn nog geen informanten toegevoegd.',
      noProfessionals: 'Er zijn geen professionals gevonden',
      noQuestionnaires: 'Er zijn geen vragenlijsten beschikbaar.',
      patientInfo: ({ linked }) => `${linked(patientLabel())} gegevens`,
      questionnaireModalIntroduction: 'Je nodigt <a>{displayName}</a> uit voor de vragenlijst "{questionnaireName}". | Je nodigt een nieuwe patiënt uit voor de vragenlijst "{questionnaireName}".',
      questionnaireSenderIsRespondent: 'Je nodigt een behandelaar uit om de vragenlijst "{questionnaireName}" in te vullen.',
      readOnlyMessage: 'Programma\'s kunnen worden toegevoegd aan een werkmap zonder e-mailadres, maar alleen om te bekijken. Opdrachten kunnen niet worden ingevuld.',
      recurrenceDate: 'Herhaal datum',
      removeLastInformant: 'Zonder informant kun je de vragenlijst niet versturen. Weet je zeker dat je de informant wilt verwijderen?',
      removeQuestionnaire: 'Weet je zeker dat je de vragenlijst wilt verwijderen? Dit kan niet ongedaan gemaakt worden.',
      saveInformant: 'Opslaan informant',
      searchCarePlan: "Iets toevoegen aan de werkmap? Zoek in de programma's en vragenlijsten:",
      selectProfessional: 'Selecteer een behandelaar',
      sendOn: 'Versturen op',
      sendQuestionnaireOn: 'Verstuur de vragenlijst op:',
      sentOn: 'Verstuurd op:',
      sets: 'Werkmap sets',
      topQuestionnaires: 'Top vragenlijsten',
      topTreatments: "Top programma's",
      zeroAdded: '0 toegevoegd',
    },
    carePlanParticipant: {
      archiveConfirmationModalText: ({ linked }) => `Je staat op het punt om deze werkmap te archiveren. Na het archiveren ontvang je geen informatie meer over de voortgang van deze ${linked(patientLabel())}.`,
      archivingError: 'Het dossier kon niet worden gearchiveerd',
      archivingSuccess: 'Het dossier is gearchiveerd',
      canAccess: 'Gedeeld met',
      deleteError: 'Er is een fout opgetreden bij het verwijderen. Probeer het nogmaals.',
      deleteSuccess: 'Succesvol verwijderd!',
      exportFile: 'Download deze werkmap',
      questionnaireToBeFilledByPatient: 'In te vullen door {name}',
      questionnaireToBeFilledByProfessional: 'Moet worden ingevuld door',
      questionnaireToBeFilledByYou: 'Door jou in te vullen',
      goalPlaceholder: 'Stel een doel',
      helpingActions: 'Helpende acties',
      krachtplan: {
        habits: 'Gewoontes',
        helpingActions: 'Helpende acties',
        noAccess: '{participantName} heeft een actief Krachtplan, maar nog geen toegang verleend voor inzage. Vraag {participantName} om toegang te verlenen.',
        noAccessTitle: 'Je hebt geen toegang tot Krachtplan',
        noAccount: '{participantName} is uitgenodigd voor Krachtplan, maar heeft nog niet geactiveerd.',
        noData: 'Er zijn geen registraties geregistreerd binnen deze periode, probeer een andere periode.',
        noHabits: 'Er zijn nog geen relevante gewoontes geregistreerd voor {mood}.',
        noHelpingActions: 'Er zijn nog geen relevante helpende acties geregistreerd voor {mood}.',
        notAcceptedCarePlan: 'Vraag {participantName} om de uitnodiging voor het zorgplan te accepteren.',
        selectHabit: 'Selecteer een stemming om de relevante gewoontes te bekijken.',
        selectHelpingAction: 'Selecteer een stemming om de relevante helpende acties te bekijken.',
        title: 'Krachtplan',
        weeks: '{weekCount} week overzicht | {weekCount} weken overzicht',
      },
      monitor: 'Monitor',
      noActions: 'Er zijn geen helpende acties geregistreerd voor deze stemming.',
      noGoalForCarePlan: 'Er is nog geen doel gesteld voor deze werkmap.',
      searchWithInOrganisation: 'Zoek binnen uw organisatie',
      selectMood: 'Selecteer een stemming om de nuttige acties te zien',
      shareCarePlanButton: 'Werkmap delen',
    },
    carePlanParticipantShared: {
      sharedInfo1: "Deze patiënt heeft één of meerdere programma's met je gedeeld. Het is mogelijk dat je niet alle programma's uit de werkmap in kunt zien.",
      sharedInfo2: ({ linked }) => `Omdat je niet de hoofdverantwoordelijke bent voor deze ${linked(patientLabel())}, kan je de inhoud van de werkmap niet aanpassen.`,
      sharedInfoTitle: 'Goed om te weten',
    },
    carePlanMonitor: {
      itMayTakeSomeTime: 'Het kan even duren voor de cliënt het verzoek beantwoordt',
      noAccessToMonitor: 'Je hebt geen toegang tot de monitor(s)',
      noMonitorsStartedYet: 'Nog geen monitor(s) gestart',
      requestAccess: 'Vraag toegang',
      requestAccessToMonitor: 'Vraag toegang tot de monitor(s) van een cliënt',
      requestSend: 'Verzoek verstuurd',
      requestSendSuccess: 'Verzoek is verstuurd',
      sendReminder: 'Herinnering sturen',
    },
    carePlanSidebar: {
      professionalsWithAccess: 'Behandelaar met toegang',
    },
    carePlanInvitePatient: {
      inviteOptionModal: {
        alertMessage: 'Let op: na het aanmaken van de werkmap kan je hier geen e-mailadres meer aan toevoegen.',
        hasNoPatientEmailDescription: ({ linked }) => `Alleen jij hebt toegang tot de gegevens van deze ${linked(patientLabel())}. De ${linked(patientLabel())} kan de opdrachten op locatie volgen maar heeft geen account en kan niet inloggen.`,
        hasPatientEmailDescription: ({ linked }) => `De ${linked(patientLabel())} ontvangt een uitnodiging via e-mail en krijgt toegang tot het platform via een eigen account.`,
        title: ({ linked }) => `Heeft je ${linked(patientLabel())} een e-mailadres?`,
      },
    },
    chat: {
      addNewConversationError: 'Er is een fout opgetreden bij het opstarten van het gesprek. Probeer het nogmaals.',
      addNewConversationSuccess: 'Er is een nieuw gesprek toegevoegd aan je lijst',
      allFiles: 'Alle documenten',
      audio: 'Audio',
      chatConversationEmptyText: 'Je start nu een gesprek met <b>{name}</b> van uit jouw contactenlijst. Dit is een privégesprek dat niemand anders kan zien.',
      connectionErrorMessage1: 'Verbinding verbroken, maak opnieuw verbinding.',
      connectionErrorMessage2: 'Als er niets verandert, probeer het dan later nog eens.',
      conversationListEmptySearchText: 'Dit contact komt niet voor in je netwerk. Probeer nogmaals te zoeken met een andere spelling.',
      conversationListEmptySearchTitle: '"{name}" niet gevonden',
      conversationListEmptyText: ' Klik op de plusknop hierboven om een nieuw gesprek te starten.',
      conversationListEmptyTitle: 'Je hebt nog geen berichten',
      conversationListTitle: 'Berichten',
      downloadFile: 'Download het bestand',
      emptyConversationContent: 'Jouw berichten en gespreksgeschiedenis vind je hier terug.',
      emptyConversationTitle: 'Welkom bij jouw berichten.',
      file: 'Bestand',
      image: 'Plaatje',
      messageDeleted: 'Dit bericht is verwijderd.',
      newConversationModalTitle: 'Nieuw gesprek',
      archivedChatMessage: 'Deze chat is gearchiveerd.',
      searchBarPlaceholder: 'Zoek in berichten',
      searchContactIntroduction: 'Start een nieuw gesprek! Typ hieronder de naam in en druk op Enter om te zoeken. Selecteer dan de persoon met wie je een gesprek wilt starten en klik op Starten om het gesprek te starten.',
      sharedFile: 'Gedeeld bestand:',
      uploadFileError: 'Er is een fout opgetreden bij het uploaden van het bestand. Probeer opnieuw.',
    },
    coaching: {
      noCoaches: 'Er zijn geen coaches beschikbaar.',
      paginationLoading: 'Laad coaches...',
      paginationScrollDown: 'Scroll naar beneden voor meer coaches',
      subtitle: 'Er is {count} organisatie met coaches | Er zijn {count} organisaties met coaches',
      title: 'Coaching',
    },
    companyInformation: {
      version: 'versie',
    },
    createCarePlanUnderOrganisation: {
      title: `Sluit je aan bij {name} in ${brand.name}`,
      subtitle: 'Sluit je aan bij {name} om toegang te krijgen tot alle zelfhulp programma\'s binnen deze organisatie. Dit zijn jouw account gegevens:',
      carePlanCreated: 'Gefeliciteerd, je hebt een werkmap met {name}.',
    },
    dataStorage: {
      continueCarePlan: 'Doorgaan met je werkmap',
      continueCarePlanContent: 'Gelukkig kun jij gewoon verder werken in jouw werkmap. Voordat je verder kunt gaan, heeft Therapieland wel toestemming nodig voor het beheren van jouw gegevens. Je kunt deze toestemming op elk moment weer intrekken.',
      deleteAccount: 'Verwijder je account',
      deleteAccountText1: 'Het zou kunnen dat jouw professional nog wel een PDF-dossier heeft gedownload van de informatie in jouw werkmap. Wil je deze graag laten verwijderen, kun je contact opnemen met deze persoon met het verzoek dit PDF-dossier uit diens archief te verwijderen.',
      deleteAccountText2: `Als je niet wilt dat je gegevens in ${brand.name} worden opgeslagen, kun je je zorgverlener hiervan direct op de hoogte stellen. Op jouw verzoek zal hij of zij al je gegevens uit ${brand.name} verwijderen. Je account wordt dan verwijderd en je hebt dan geen toegang meer tot je zorgplan.`,
      deleteAccountTitle: `Het verwijderen van je persoonlijke gegevens uit ${brand.name}`,
      title: `Hoi, voordat je verder kunt werken in je ${brand.name} werkmap hebben we je toestemming nodig.`,
      whatHappened: 'Wat is er gebeurd',
      whatHappenedContent: `Je werkmap is door je professional gearchiveerd. Deze persoon is daardoor niet langer aan jou gekoppeld in ${brand.name} en heeft daarmee geen toegang meer tot jouw werkmap en gegevens.`,
    },
    deactivatedAccount: {
      message: 'Je account is gedeactiveerd',
    },
    deleteAccount: {
      acceptInvitation: 'Accepteer uitnodiging',
      dataDeleted: 'Je gegevens zijn verwijderd',
      question: 'Wil je de uitnodiging afslaan?',
    },
    deletedAccount: {
      message: 'Je account is verwijderd',
    },
    expiredToken: {
      title: 'Uitnodigingslink verlopen',
      text: 'Sorry, deze link is verlopen! Neem contact op met je begeleider.',
    },
    forgotPassword: {
      requestNewPassword: 'Om een nieuw wachtwoord op te vragen, vul je hier je e-mailadres in. Je ontvangt dan een mail van ons met instructies om je wachtwoord opnieuw in te stellen.',
      textConfirmation: "Er is een mail verstuurd naar {email} met een linkje waarmee je je wachtwoord kunt instellen. <br><br> Als je de mail niet hebt ontvangen, kijk dan even of hij in je Spam terecht is gekomen. Als dat niet het geval is, neem dan contact op met onze helpdesk op telefoonnummer <a href='tel:+31207712848'>020-7712848</a>.",
    },
    generalError: {
      generalText: `Er is een fout opgetreden. Neem contact op met ons via <a href='mailto:${brand.emailInfo}'>${brand.emailInfo}</a> of <a href='tel:+31207712848'>020-7712848</a> en dan proberen we je te helpen.`,
      redirectText: 'Klik op de knop om terug te gaan.',
      questionnaireCompleted: 'Deze vragenlijst is al afgerond en de antwoorden zijn verstuurd naar de professional. De antwoorden kunnen niet meer gewijzigd worden. <p>Je kunt nu dit scherm sluiten.</p>',
      questionnaireNotAvailable: 'Deze vragenlijst is niet meer beschikbaar omdat de uitnodiging is ingetrokken. Indien je wel toegang zou moeten hebben, zoek dan contact met degene die je heeft uitgenodigd. <p>Je kunt deze pagina nu sluiten.</p>',
    },
    login: {
      accountDeclined: 'Heb je de uitnodiging geweigerd? Neem dan contact op met jouw professional.',
      canNotActivate: 'We kunnen je account niet activeren, omdat je account al actief is. Probeer in te loggen.',
      existingAccount: 'Er bestaat al een account op dit e-mailadres, dus je kunt gelijk inloggen met je bestaande gegevens.',
      forgotPassword: 'Wachtwoord vergeten?',
      imageLoginManAlt: `Man met tablet die laat zien hoe mooi ${brand.name} 2.0 is`,
      loginProblems: 'Problemen met inloggen?',
      loginText: 'Log hier in met je account.',
      noAccount: 'Nog geen account?',
      subTitle: 'Log hier in met je account.',
      supportTeam: "Hulp nodig? Bel onze helpdesk: <a href='tel:+31207712848'>020-7712848</a>",
      title: `Inloggen op ${brand.name}`,
      usp1: 'Nieuw design',
      usp2: 'Verbeterde functionaliteiten',
      usp3: 'Betere ervaring',
      uspTitle: `${brand.name} 2.0!`,
    },
    monitor: {
      countingMonitor: 'Monitoren met turven',
      countingRecommendations: 'roken, piekeren, complimenten geven.',
      createMonitor: 'Wat voor monitor wil je maken?',
      deletedFail: 'Monitor kan niet worden verwijderd. Probeer het nog eens.',
      deletedSuccess: 'Monitor is verwijderd',
      emotionLimitIntro1: 'Wat betekent',
      emotionLimitIntro2: 'in jouw monitor?',
      emotionMonitor: 'Monitoren met smileys',
      emotionRecommendations: 'slaap, stress en stemming.',
      highRecord: 'Hoge score',
      highRecordText1: 'In deze monitor betekent',
      highRecordText2: 'een hoge score in de grafiek.',
      imageAltCountingChart: 'monitor turf grafiek',
      imageAltEmotionChart: 'monitor emotie grafiek',
      imageAltEmotionDirectionDown: 'monitor emotie afbeelding laag',
      imageAltEmotionDirectionUp: 'monitor emotion afbeelding hoog',
      inputNameLabel: 'Geef je monitor een naam!',
      inputNamePlaceholder: 'Vul hier de naam van je monitor in...',
      intro: 'Hier kun je aan de slag met monitors. Je kunt kiezen uit twee soorten monitors die je helpen meer inzicht te krijgen in je emoties en gedrag.',
      lowRecord: 'Lage score',
      lowRecordText1: 'In deze monitor betekent',
      lowRecordText2: 'een lage score in de grafiek.',
      month: 'Maand',
      newMonitor: 'Nieuwe monitor maken',
      newMonitorAdded: 'Nieuwe monitor toegevoegd',
      newMonitorAddedError: 'Fout bij toevoegen nieuwe monitor',
      recommendations: 'Bijvoorbeeld',
      startingDate: 'Start datum',
      title: 'Monitors',
      titleCounter: 'Hoe gaat het?',
      titleEmotion: 'Hoe gaat het vandaag?',
      week: 'Week',
    },
    participantActivity: {
      activities: 'Acties',
      lastLogin: 'Laatste inlog',
      newActivities: 'Nieuwe acties',
      noActivities: 'Er zijn geen {activityType}.',
      pinnedActivities: 'Vastgezette acties',
      previousActivities: 'Vorige acties',
      started: 'Is begonnen',
      startingDate: 'Start datum',
      tipsNew: 'Nieuwe acties?',
      tipsNewTip: 'Bekijk ze en geef feedback!',
      tipsNoNew: 'Geen nieuwe acties?',
      tipsNoNewTip: 'Motiveer met een bericht.',
      toBeFilledIn: 'Nog in te vullen',
      treatment: 'programma',
    },
    patientCarePlans: {
      allTreatments: "Alle programma's",
      askForInvitation: 'Er staat nog niets in je werkmap. Jouw begeleider kan onderdelen toevoegen.',
      carePlanCount: 'Je hebt {count} werkmap | Je hebt {count} werkmappen',
      completedOn: 'Ingevuld op',
      emptyText: 'Er is nog niets toegevoegd aan je werkmap.',
      emptyTextSelfHelp: 'Je bent nog niet gestart. Laten we beginnen!',
      emptyTitle: 'Dit is je werkmap',
      noGoal: 'Er is geen doel gesteld.',
      noResults: 'Geen resultaten gevonden.',
      questionnaireCount: '{count} vragenlijst | {count} vragenlijsten',
      seeMore: 'Wil je meer doen? Kijk hier!',
      selfHelpText: 'Elke dag is een kans om je geest te trainen voor een blijer en gezonder leven.',
      toBeFilledIn: 'Nog in te vullen',
      toBeStarted: 'Nog niet gestart',
      treatmentCount: "{count} programma | {count} programma's",
      treatmentListInfo: "Klik hieronder om te zien met welke programma's je zelf al kunt starten.",
    },
    patients: {
      activitiesCount: '{activities} nieuwe actie | {activities} nieuwe acties',
      activity: 'Actie',
      carePlan: 'Werkmap',
      confirmationMessageDeleteInvitation1: 'Wil je deze uitnodiging verwijderen?',
      confirmationMessageDeleteInvitation2: 'De mail die verstuurd is zal niet worden verwijderd.',
      confirmationMessageDeletePatient1: 'Wil je dit dossier verwijderen?',
      confirmationMessageDeletePatient2: 'Alle informatie zal worden verwijderd.',
      confirmationMessageResendInvitation: 'Wil je deze uitnodiging opnieuw versturen?',
      deleteInvitationError: 'Er is een fout opgetreden bij het versturen van de uitnodiging. Probeer het opnieuw.',
      deleteInvitationSuccess: 'Uitnodiging is verwijderd.',
      deletePatientError: 'Er is een fout opgetreden bij het verwijderen van het dossier. Probeer het opnieuw.',
      deletePatientSuccess: 'Dossier is verwijderd',
      emailEditError: 'Er is een fout opgetreden bij het wijzigen van het e-mailadres. Probeer het opnieuw.',
      emailEditSuccess: ' E-mailadres is gewijzigd',
      emailUsed: 'E-mail gebruikt voor uitnodiging',
      exportCarePlanError: 'Er is een fout opgetreden met het downloaden van het dossier. Probeer het nogmaals.',
      invitation: 'Uitnodiging',
      noData: ({ linked }) => `Geen ${linked(patientLabel())} gevonden`,
      paginationLoading: ({ linked }) => `Laad ${linked(patientLabel())} in... `,
      paginationScrollDown: ({ linked }) => `Scroll naar beneden om meer ${linked(patientLabel())} te zien`,
      placeholderDatePicker: 'Zoek op geboortedatum',
      placeholderSearchBar: 'Zoek op naam of e-mail',
      professional: 'Behandelaar',
      profile: 'Profiel',
      resendInvitationError: 'Er is een fout opgetreden bij het hersturen van de uitnodiging. Probeer het nog een keer.',
      resendInvitationSuccess: 'Uitnodiging opnieuw verzonden',
      sentOn: 'Verstuurd op:',
      sortBy: 'Sorteer op',
      tabArchived: 'Archief({patients})',
      tabOpenInvites: '(Nog) niet geaccepteerd({patients})',
      tabShared: 'Gedeeld({patients})',
      time: 'Tijd',
      sorting: {
        alphabeticalFirstNameAsc: 'Naam (A-Z)',
        alphabeticalFirstNameDesc: 'Naam (Z-A)',
        alphabeticalLastNameAsc: 'Achternaam (A-Z)',
        alphabeticalLastNameDesc: 'Achternaam (Z-A)',
        archivedDate: 'Archiveer datum',
        lastActivity: 'Laatste actie',
        sendingInvitation: 'Uitnodiging verstuurd',
      },
    },
    permissionGrantedKrachtplan: {
      title: 'Toestemming is verleend',
      subTitle: 'De registraties die je invoert in de Krachtplan-app zullen nu zichtbaar zijn voor de organisatie.',
    },
    questionnaireAssignment: {
      completionSuccess: 'De vragenlijst is ingevuld!',
      concerning: 'Betrokken',
      invitedBy: 'Uitgenodigd door: {name} van {organisation}',
      minutes: '{minutes} minuut | {minutes} minuten',
      professionalCompletedQuestionnaire: 'Klik op de knop om de resultaten te bekijken.',
      questions: '{questions} vraag | {questions} vragen',
      skipAndContinueModalText: 'Overslaan en doorgaan',
      thankYou: 'Dankjewel! De vragenlijst is afgerond.',
      thanksFillingInQuestionnaire: 'Dank voor het invullen van de vragenlijst!',
      toBeFilledIn: 'In te vullen door:',
      totallyAgreeModalText: 'Helemaal mee eens',
      totallyDisagreeModalText: 'Helemaal mee oneens',
      useOfQuestionnaire: 'Het inzetten van deze vragenlijst heeft positief bijgedragen aan de kwaliteit van mijn behandeling.',
      whyDidYouChooseThisReview: 'Waarom heb je voor deze beoordeling gekozen? (optioneel)',
      youHaveBeenInvited: 'Je bent uitgenodigd om een vragenlijst in te vullen:',
    },
    questionnaireAssignmentSuicidalModal: {
      oke: 'oké',
      subtext: 'Bel je huisarts of stichting 113 zelfmoord preventie',
      text: 'Je hebt aangegeven zelfmoord gedachten te hebben. Stichting 113 zelfmoord preventie is er voor je, chat of bel met hen geheel anoniem.',
      title: 'Denk je aan zelfmoord?',
    },
    questionnaireInformant: {
      willBeSent: 'De resultaten zijn verzonden naar {name}',
      closeNow: 'Je kunt dit tabblad nu sluiten.',
    },
    questionnaireList: {
      addToCarePlanSuccess: 'Gelukt! De vragenlijst is toegevoegd!',
      from: 'Van {supplier}',
      noQuestionnairesFound: 'Geen vragenlijsten gevonden die aan je opdracht voldoen.',
      paginationLoading: 'Laad vragenlijsten...',
      paginationScrollDown: 'Scroll naar beneden voor meer vragenlijsten',
      price: '€ {price}/afname',
      questionnairesAvailable: '{count} vragenlijsten beschikbaar',
      searchPlaceholder: 'Zoek vragenlijsten',
      toBeFilledByYou: 'In te vullen door <b>jou</b>',
      toBeFilledByInformant: 'In te vullen door <b>een informant</b>',
    },
    register: {
      activateAccount: 'Account activeren',
      addPasswordActivationAccount: 'Voeg een wachtwoord toe om je account te activeren.',
      createAccount: 'Account aanmaken',
      newAccountSlug: `Welkom op ${brand.name}! Je bent uitgenodigd door <b>{organisationName}</b>`,
      optionsPatientSubtitle: `${brand.name} geeft je toegang tot veel programma's en vragenlijsten die je ondersteunen wanneer je aan je mentale gezondheid werkt`,
      optionsPatientTitle: 'Ik zoek online hulp',
      optionsSubtitle: 'Selecteer wat voor een account je wil hebben.',
      optionsTitle: 'Maak een account aan',
      organisationConfirmationSubtitle: 'Je kunt nu inloggen!',
      organisationConfirmationTitle: 'Je account is succesvol geactiveerd!',
      organisationRegisterSubtext: 'Met een proefaccount krijg je als zorgprofessional een maand lang toegang tot onze online programma’s. Wij nemen binnen één werkdag contact met je op.',
      organisationRegisterText: 'Proefaccount behandelaar aanvragen',
      organisationRequestConfirmationSubtitle: 'We nemen binnen 2 werkdagen contact met je op om direct aan de slag te kunnen!',
      organisationRequestConfirmationTitle: `Bedankt voor het registreren op ${brand.name}.`,
      organisationRequestContact: 'Contact',
      organisationRequestOrganisation: 'Organisatie',
      publicConfirmationSubtitle: "Als je geen e-mail hebt ontvangen, kijk dan eerst of hij in de spam terecht is gekomen. Als dit niet het geval is, neem dan contact op met onze helpdesk op <a href='tel:+31207712848'>020-7712848</a>.",
      publicConfirmationTitle: 'Er is een mail verstuurd naar {email} met een link. Klik op de link in de e-mail om je account te activeren. Daarna kun je inloggen.',
      publicFieldPassword: 'Maak een wachtwoord aan',
      publicRegisterText: 'Maak je account aan',
      publicTitle: 'Registreren',
      selectChoice: 'Selecteer keuze',
      Therapieland: {
        optionsProfessionalSubtitle: `Wanneer je iemand professioneel begeleid kun je diegene uitnodigen voor de e-health programma’s en vragenlijsten van ${brand.name}.`,
        optionsProfessionalTitle: 'Ik wil graag mensen helpen',
      },
      Gezondeboel: {
        optionsProfessionalSubtitle: "Als een organisatie of professional kan je mensen uitnodigen voor Gezondeboel en ze programma's & vragenlijsten sturen.",
        optionsProfessionalTitle: 'Ik ben een professional en wil mensen helpen',
      },
    },
    resetPassword: {
      confirmPassword: 'Bevestig wachtwoord',
      newPassword: 'Nieuw wachtwoord',
      resetPassword: 'Wachtwoord vergeten',
      resetPasswordCompleted: 'Je wachtwoord is gereset',
      setYourNewPassword: 'Stel je nieuwe wachtwoord in',
    },
    securityCode: {
      emailHasBeenSent: 'Er is zojuist een beveiligingscode verstuurd naar je e-mailadres.',
      enterSecurityCode: 'Voer je beveiligingscode in',
      invalidCode: 'De code die je hebt ingevoerd is onjuist. Probeer het opnieuw.',
      keepWindowOpen: 'Zorg ervoor dat je dit scherm open laat terwijl je je inbox controleert.',
      limitReached: 'Je hebt het limiet bereikt. Over 30 minuten kun je de code weer opnieuw versturen.',
      newCodeSent: 'De nieuwe code is naar je e-mail verstuurd',
      tryAgain: 'Je kunt de code maar één keer per 5 seconden versturen.',
    },
    settings: {
      '2faText': 'Is je app niet handig in gebruik? Hier vind je backup tokens.',
      accountChanges: 'Wijzigen of opzeggen van je licentie',
      accountChangesText: `Je licentie opzeggen, een extra service toevoegen, of een ander programma pakket kiezen? Dit kun je doen door het formulier in te vullen op onze website: <a href='${brand.websiteAccountChanges}'>${brand.websiteAccountChanges}</a>`,
      backupTokens: 'Backup tokens',
      basicInformation: 'Basisgegevens',
      certification: 'Certificering',
      changeBasicInformation: 'Je gegevens zijn opgeslagen',
      changeEmailActivation: 'Een activatiemail is verstuurd naar je nieuwe e-mailadres. \n Open de link in de mail om de wijziging definitief te maken',
      changeEmailConfirmation: "Weet je zeker dat je je e-mailadres wilt veranderen naar <a href='mailto:{email}'>{email}</a>? <br><br>Er wordt vervolgens een e-mail naar je nieuwe e-mailadres gestuurd met een link waarmee je de wijziging kunt bevestigen.",
      changePasswordSuccess: 'Wachtwoord succesvol gewijzigd',
      copyEverything: 'Kopieer alles',
      deactivateAccount1: `Je ${brand.name} account deactiveren`,
      deactivateAccount2: 'We bewaren je gegevens voor als je beslist om in de toekomst terug te komen.',
      deleteAccount1: 'Verwijder je account',
      deleteAccount2: `Deze optie verwijdert al je data op ${brand.name}. Dit kan niet meer ongedaan worden gemaakt.`,
      emailNotificationItem1: "Systeem updates, nieuwe features, nieuwe programma's",
      emailNotificationItem2: 'Nieuwe berichten van je netwerk',
      emailNotificationLabel: `Wil je e-mails ontvangen van ${brand.name}?`,
      emailUpdatedText: 'Vanaf nu kun je met dit e-mailadres inloggen en eventuele e-mails ontvang je ook op dit e-mailadres.',
      emailUpdatedTitle: 'Je e-mail adres is succesvol bijgewerkt!',
      generateMore: 'Genereer extra',
      myTokens: 'Mijn tokens',
      preferences: 'Voorkeuren',
      researchAgreement: 'Onderzoekstoestemming',
      researchAgreementInput: 'Onderzoekstoestemming staat',
      researchAgreementLink: 'Meer informatie over onderzoekstoestemming',
      researchAgreementText: `Bij ${brand.name} doen wij onderzoek om e-health te verbeteren. Mogen wij jouw gegevens anonimiseren om deze te gebruiken voor het doen van onderzoek?`,
      tokensCopied: 'Tokens gekopieerd',
    },
    socialEnvironment: {
      return: 'Terug naar programma',
      previewText: 'Dit is een voorbeeld van het programma dat je met anderen kunt delen om hen te helpen begrijpen waar je aan werkt.',
      previewTextSmall: 'Voorbeeld',
    },
    survey: {
      title: 'Tevredenheidsmeting',
      text: 'Beste deelnemer, \n \n Fijn dat je deze vragen wilt beantwoorden. Onderstaande vragen gaan over het programma {treatmentName}. Zou je deze zo eerlijk mogelijk willen beantwoorden?',
      surveyFilled: 'Vragenlijst is al ingevuld.',
    },
    treatment: {
      endOfTreatment: 'Einde programma',
      finishTreatment: 'Beëindig het programma',
      messagePatient: ({ linked }) => `Stuur een bericht naar je ${linked(patientLabel())} over deze stap`,
      messageProfessional: 'Stuur een bericht naar je zorgverlener over deze stap',
    },
    treatmentAssignmentStepPayment: {
      info: 'Om door te kunnen gaan in dit programma verzoeken we je nu te betalen. De prijs van de behandeling staat hieronder. \nAls je hebt betaald, kan je verder werken aan alle stappen in het programma!\n',
      invoiceInfo: 'Deze information vind je terug op de factuur. Check deze informatie goed. Bij het declareren van zorgkosten wordt incorrecte of incomplete informatie mogelijk afgewezen door de verzekeraar.',
      invoiceTitle: 'Factuur details',
      paymentInfo: 'Na betaling wordt het programma hervat.',
      paymentMethod: 'Betaal met iDeal',
      paymentTitle: '<span>Betaal</span> om verder te gaan',
      personalData: `Therapieland verwerkt bovenstaande persoonsgegevens voor het opmaken van het betalingsbewijs. Je geeft toestemming tot verwerking van deze gegevens door de betaling af te ronden. Lees ons <a href='${brand.websitePrivacy}' target='_blank'>privacy statement</a> voor meer informatie.`,
      placeholderFirstName: 'Vul je voornaam in',
      placeholderInfix: 'Vul je tussenvoegsel in',
      placeholderLastName: 'Vul je achternaam in',
      price: '€ {price} / jaar',
    },
    treatmentAssignmentStepPaymentStatus: {
      accessTreatment: 'Je hebt nu toegang tot:',
      cantProcessPayment: 'We kunnen uw betaling niet verwerken, probeer het opnieuw',
      emailedInvoiceTo: 'Wij hebben de factuur gemaild naar:',
      paymentFailed: 'Betaling mislukt',
      paymentSuccessful: 'Betaling gelukt',
    },
    treatmentAssignmentsShare: {
      emailLabel: 'Aan (e-mailadres)',
      heIs: 'Hij/zij is',
      invitationSuccess: 'Je uitnodiging is verzonden naar de behandelaar.',
      itemSelected: '{count} item geselecteerd | {count} items geseleteerd',
      sectionText: 'In de lijst hieronder kun je aangeven waar je de professional toegang toe wilt geven.',
      shareFiles: "Deel programma's",
      title: 'Deel je programma’s met andere professionals',
    },
    treatmentDetail: {
      aboutTheProgram: 'Over het programma',
      exportTreatmentPending: 'Programma wordt gedownload',
      exportTreatmentProgress: 'Download voortgang programma',
      finishedTreatmentMessage1: 'Je hebt het programma afgerond! Hopelijk heeft het geholpen om dichterbij je doel te komen.',
      finishedTreatmentMessage2: 'Heb je op een later moment behoefte een video terug te kijken of een opdracht opnieuw te maken? Dat kan natuurlijk altijd! Het programma blijft altijd beschikbaar.',
      finishedTreatmentTitle: 'Goed bezig',
      howToUseIt: 'Over het programma',
      library: 'Bibliotheek',
      noAccessStepsForPatients: 'Programmastappen zijn niet toegankelijk wanneer het programma nog niet is toegevoegd aan je werkmap.',
      noAccessStepsOutsidePackage: "Programmastappen zijn niet toegankelijk voor programma's buiten je pakket.",
      noAnswerFilledIn: 'Er zijn nog geen antwoorden ingevuld',
      questionnaireElement: 'Bezoek de stap om het result van de vragenlijst te bekijken',
      send: 'SEND',
      sessionNumber: 'Sessie {number}:  {name}',
      steps: 'Stappen',
      suitableFor: 'Geschikt voor',
      tabIntroduction: 'Introductie',
      programContent: 'Programma content',
    },
    treatmentDetailModal: {
      text: 'Wil je de behandeling starten of meer behandelingen toevoegen?',
      title: '{treatmentName} is toegevoegd aan uw werkmap',
    },
    treatmentDetailIntroduction: {
      jumpToProgramContent: 'Direct naar programma inhoud',
      programIntroduction: 'Programma introductie',
    },
    treatmentInformation: {
      aboutTheProgram: 'Over het programma',
      allInstructions: 'Alle instructies',
    },
    treatmentList: {
      addNew: "Voeg programma's toe aan werkmap",
      addToCarePlanError: 'Het programma kon niet worden toegevoegd. Probeer het nog eens.',
      addToCarePlanSuccess: 'Gelukt! Het programma is toegevoegd!',
      alphabetical: 'alfabetisch',
      complaint: 'Klacht',
      description: "Op deze pagina kun je programma's bekijken en toevoegen aan een werkmap.",
      disorder: 'Stoornis',
      emptyCardContent: 'Open het programma om de inhoud te bekijken',
      filter: 'Filter',
      method: 'Methode',
      mostlyUsed: 'meest gebruikt',
      noThemesFound: "Geen thema's gevonden die overeenkomen met je zoekterm.",
      noTreatmentsFound: "Geen programma's gevonden die aan je opdracht voldoen.",
      outOfPackage: 'Buiten je pakket',
      paginationLoading: "Laad programma's... ",
      paginationScrollDown: "Scroll naar beneden voor meer programma's",
      parents: 'Ouders',
      price: '€ {price}',
      selectTheme: 'Selecteer thema\'s om bijpassende programma\'s te vinden',
      sessions: '{sessions} sessies',
      searchPlaceholder: 'Zoek programma\'s',
      searchThemes: 'Zoek thema\'s',
      sortBy: 'Sorteer op {name}',
      targetGroup: 'Geschikt voor:',
      themes: {
        addiction: 'Verslaving',
        anxietyAndPanic: 'Angst en paniek',
        attentionAndConcentration: 'Aandacht en concentratie',
        autism: 'Autisme',
        balanceAndRecovery: 'Balans en herstel',
        compulsion: 'Dwang',
        coronavirus: 'Coronavirus',
        eatingDisorders: 'Eetproblematiek',
        emotionsAndMood: 'Emoties en stemming',
        identityAndMeaning: 'Identiteit en zingeving',
        lifestyle: 'Leefstijl',
        lifeStage: 'Levensfase',
        lossAndGrief: 'Verlies en rouw',
        mindsetAndPositivity: 'Mindset en positiviteit',
        neurocognitiveDisorder: 'Neurocognitieve stoornis',
        parenting: 'Opvoeden',
        personalityDisorders: 'Persoonlijkheidsproblematiek',
        physicalComplaintsAndIllnesses: 'Lichamelijke klachten en ziekten',
        productivityAndSkills: 'Productiviteit en vaardigheden',
        psychologicalMethods: 'Psychologische methoden',
        psychoticDisorder: 'Psychotische stoornis',
        relationshipsAndIntimacy: 'Relaties en intimiteit',
        safetyAndHealth: 'Veiligheid en gezondheid',
        selfEsteem: 'Zelfbeeld',
        socialAndConnectedness: 'Sociaal en verbondenheid',
        stressAndBurnout: 'Stress en overspanning',
        trauma: 'Trauma',
      },
      themeSelection: 'Thema selectie',
      treatmentAlreadyInCarePlanError: 'Programma zit al in de werkmap.',
      treatmentToCarePlan: 'Je hebt het programma aan je werkmap toegevoegd.',
      treatmentsAvailable: "{treatments} programma's beschikbaar",
      treatmentsOutOfPackage: "{treatments} programma's buiten het pakket",
      unpaidOptions: 'Onbetaalde opties',
    },
    twoFactorAuth: {
      altImage: 'two factor image',
      altImageQrCode: 'qr-code for two factor authentication',
      altLogo: 'two factor authenticator logo',
      backupToken: 'Backup token',
      backupTokenExplanation: 'Je kunt "backup tokens" genereren. Deze worden automatisch aangemaakt door het systeem en kunnen maar 1 keer gebruikt worden. Je kunt ze terugvinden in de instellingen of door op het knopje hieronder te klikken.',
      downloadApp: 'Download een app voor twee-factor authenticatie, zoals "Google Authenticator".',
      fillAndDone: 'Vul de code in en je bent klaar!',
      fillBackupToken: 'Vul je backup token hieronder in:',
      fillBackupTokenHere: 'Vul je backup token hieronder in:',
      fillOneTimeToken: 'Vul de eenmalige token in.',
      fillToken: 'Vul je token hieronder in:',
      fillTokenHere: 'Vul je token hier in',
      goodToKnow: 'Goed om te weten',
      howItWorks: 'Hoe werkt het?',
      hurraySuccess: 'Gelukt! Je hebt twee factor authenticatie geinstalleerd!',
      installAndScan: 'Installeer je app en scan de QR-code',
      invalidToken: 'Je token is ongeldig.',
      notConvenient: 'Komt het gebruik van de app je niet uit?',
      oneTimeToken: 'De app geeft je een eenmalige token.',
      scanQr: 'Je krijgt een QR code en kan deze scannen met de app.',
      scanQrBelow: 'Scan de QR-code hieronder.',
      subtitle: "Wachtwoorden zijn niet voldoende. Voeg extra beveiliging toe met two-factor-authentication. Wil je hier meer over weten? <a target='_blank' href='https://www.google.com/landing/2step/'>Klik hier</a>.",
      succeeded: 'Gelukt! Je hebt twee factor authenticatie geinstalleerd!',
      title: 'Tweefactorauthenticatie',
      toDashboard: 'Naar je dashboard',
      tokenExplanation: `Elke keer dat je wilt inloggen in ${brand.name}, genereert de app een nieuw token.`,
      useBackupToken: 'Gebruik backup token',
      wantToKnowMore: 'Wachtwoorden niet genoeg? Voeg een extra laag veiligheid toe met twee factor authenticatie.',
    },
    userNameInformation: {
      title: 'Mogen we je naam weten?',
      information: 'Door je naam te registreren kunnen we je hiermee aanspreken in je account.',
    },
    videoCallExternal: {
      somethingWentWrong: `Er is iets misgegaan bij het omleiden naar het video gesprek.<br><br> Neem contact op met ${brand.name} via <a href='tel:+31207712848'>020-7712848</a> of ${brand.emailInfo}.`,
    },
    widgetCarePlan: {
      carePlanContent: 'Inhoud werkmap',
      emptyCarePlanWidgetNoRecommendedTreatments: "Er zijn geen aanbevolen programma's",
      emptyCarePlanWidgetSelfHelpText: "Start hieronder een van de programma's:",
      emptyCarePlanWidgetSelfHelpTopText: 'Er staat nog niets in je werkmap.',
      emptyCarePlanWidgetText: 'Er staat nog niets in je werkmap. Jouw begeleider kan onderdelen toevoegen.',
      explore: 'Programma overzicht',
      noTreatmentsInCarePlan: 'De werkmap is leeg.',
      title: 'Werkmap',
      widgetLoading: "Bezig met het laden van je programma's...",
      widgetTitle: 'Je werkt aan',
    },
    widgetCoaching: {
      title: 'Coaching',
      widgetContent: 'Ontvang hulp van een coach voor programma advies, wanneer je vastloopt of als je extra hulp nodig hebt.',
      widgetButton: 'Bekijk mogelijkheden',
    },
  },
};
